import * as React from 'react';
import {
  useFloating,
  autoUpdate,
  offset,
  shift,
  limitShift,
  hide,
  arrow as floatingUIarrow,
  flip,
  size,
} from '@floating-ui/react-dom';
import * as ArrowPrimitive from '@radix-ui/react-arrow';
import { useComposedRefs } from '@radix-ui/react-compose-refs';
import { createContextScope } from '@radix-ui/react-context';
import { Primitive } from '@radix-ui/react-primitive';
import { useCallbackRef } from '@radix-ui/react-use-callback-ref';
import { useLayoutEffect } from '@radix-ui/react-use-layout-effect';
import { useSize } from '@radix-ui/react-use-size';

import type { Placement, Middleware } from '@floating-ui/react-dom';
import type { Scope } from '@radix-ui/react-context';
import type { Measurable } from '@radix-ui/rect';

const SIDE_OPTIONS = ['top', 'right', 'bottom', 'left'] as const;
const ALIGN_OPTIONS = ['start', 'center', 'end'] as const;

type Side = (typeof SIDE_OPTIONS)[number];
type Align = (typeof ALIGN_OPTIONS)[number];

/* -------------------------------------------------------------------------------------------------
 * Popper
 * -----------------------------------------------------------------------------------------------*/

const POPPER_NAME = 'Popper';

type ScopedProps<p> = P & { __scopePopper?: Scope };
const [createPopperContext, createPopperScope] = createContextScope(POPPER_NAME);

type PopperContextValue = {
  anchor: Measurable | null;
  onAnchorChange(anchor: Measurable | null): void;
};
const [PopperProvider, usePopperContext] = createPopperContext<poppercontextvalue>(POPPER_NAME);

interface PopperProps {
  children?: React.ReactNode;
}
const Popper: React.FC<popperprops> = (props: ScopedProps<popperprops>) => {
  const { __scopePopper, children } = props;
  const [anchor, setAnchor] = React.useState<measurable |="" null="">(널)을 반환합니다;
  반환 (
    <popperprovider scope="{__scopePopper}" anchor="{anchor}" onAnchorChange="{setAnchor}">
      {자녀}
    </popperprovider>
  );
};

팝퍼.표시 이름 = 팝퍼_이름;

/* -------------------------------------------------------------------------------------------------
 * 팝퍼 앵커
 * -----------------------------------------------------------------------------------------------*/

const ANCHOR_NAME = '팝퍼앵커';

유형 PopperAnchorElement = React.ElementRef<typeof Primitive.div="">;
유형 PrimitiveDivProps = React.ComponentPropsWithoutRef<typeof Primitive.div="">;
interface PopperAnchorProps extends PrimitiveDivProps {
  virtualRef?: React.RefObject<measurable>;
}

const PopperAnchor = React.forwardRef<popperanchorelement, PopperAnchorProps="">(
  (props: ScopedProps<popperanchorprops>, forwardedRef) => {
    const { __scopePopper, virtualRef, ...anchorProps } = props;
    const context = usePopperContext(ANCHOR_NAME, __scopePopper);
    const ref = React.useRef<popperanchorelement>(null);
    const composedRefs = useComposedRefs(forwardedRef, ref);

    React.useEffect(() => {
      // Consumer can anchor the popper to something that isn't
      // a DOM node e.g. pointer position, so we override the
      // `anchorRef` with their virtual ref in this case.
      context.onAnchorChange(virtualRef?.current || ref.current);
    });

    return virtualRef ? null : <primitive.div {...anchorProps}="" ref="{composedRefs}"></primitive.div>;
  }
);

PopperAnchor.displayName = ANCHOR_NAME;

/* -------------------------------------------------------------------------------------------------
 * PopperContent
 * -----------------------------------------------------------------------------------------------*/

const CONTENT_NAME = 'PopperContent';

type PopperContentContextValue = {
  placedSide: Side;
  onArrowChange(arrow: HTMLSpanElement | null): void;
  arrowX?: number;
  arrowY?: number;
  shouldHideArrow: boolean;
};

const [PopperContentProvider, useContentContext] =
  createPopperContext<poppercontentcontextvalue>(콘텐츠_이름)을 입력합니다;

유형 바운더리 = 엘리먼트 | 널;

유형 팝퍼콘텐츠 엘리먼트 = React.ElementRef<typeof Primitive.div="">;
interface PopperContentProps extends PrimitiveDivProps {
  side?: Side;
  sideOffset?: number;
  align?: Align;
  alignOffset?: number;
  arrowPadding?: number;
  avoidCollisions?: boolean;
  collisionBoundary?: Boundary | Boundary[];
  collisionPadding?: number | Partial<record<side, number="">>;
  sticky?: '부분적' | '항상';
  hideWhenDetached?: boolean;
  updatePositionStrategy?: '최적화된' | '항상';
  onPlaced?: () => void;
}

const PopperContent = React.forwardRef<poppercontentelement, PopperContentProps="">(
  (props: ScopedProps<poppercontentprops>, forwardedRef) => {
    const {
      __scopePopper,
      side = 'bottom',
      sideOffset = 0,
      align = 'center',
      alignOffset = 0,
      arrowPadding = 0,
      avoidCollisions = true,
      collisionBoundary = [],
      collisionPadding: collisionPaddingProp = 0,
      sticky = 'partial',
      hideWhenDetached = false,
      updatePositionStrategy = 'optimized',
      onPlaced,
      ...contentProps
    } = props;

    const context = usePopperContext(CONTENT_NAME, __scopePopper);

    const [content, setContent] = React.useState<htmldivelement |="" null="">(null);
    const composedRefs = useComposedRefs(전달된 참조, (노드) => setContent(노드));

    const [arrow, setArrow] = React.useState<htmlspanelement |="" null="">(null);
    const arrowSize = useSize(arrow);
    const arrowWidth = arrowSize?.width ?? 0;
    const arrowHeight = arrowSize?.height ?? 0;

    const desiredPlacement = (side + (align !== 'center' ? '-' + align : '')) as Placement;

    const collisionPadding =
      typeof collisionPaddingProp === 'number'
        ? collisionPaddingProp
        : { top: 0, right: 0, bottom: 0, left: 0, ...collisionPaddingProp };

    const boundary = Array.isArray(collisionBoundary) ? collisionBoundary : [collisionBoundary];
    const hasExplicitBoundaries = boundary.length > 0;

    const detectOverflowOptions = {
      padding: collisionPadding,
      boundary: boundary.filter(isNotNull),
      // with `strategy: 'fixed'`, this is the only way to get it to respect boundaries
      altBoundary: hasExplicitBoundaries,
    };

    const { refs, floatingStyles, placement, isPositioned, middlewareData } = useFloating({
      // default to `fixed` strategy so users don't have to pick and we also avoid focus scroll issues
      strategy: 'fixed',
      placement: desiredPlacement,
      whileElementsMounted: (...args) => {
        const cleanup = autoUpdate(...args, {
          animationFrame: updatePositionStrategy === 'always',
        });
        return cleanup;
      },
      elements: {
        reference: context.anchor,
      },
      middleware: [
        offset({ mainAxis: sideOffset + arrowHeight, alignmentAxis: alignOffset }),
        avoidCollisions &&
          shift({
            mainAxis: true,
            crossAxis: false,
            limiter: sticky === 'partial' ? limitShift() : undefined,
            ...detectOverflowOptions,
          }),
        avoidCollisions && flip({ ...detectOverflowOptions }),
        size({
          ...detectOverflowOptions,
          apply: ({ elements, rects, availableWidth, availableHeight }) => {
            const { width: anchorWidth, height: anchorHeight } = rects.reference;
            const contentStyle = elements.floating.style;
            contentStyle.setProperty('--radix-popper-available-width', `${availableWidth}px`);
            contentStyle.setProperty('--radix-popper-available-height', `${availableHeight}px`);
            contentStyle.setProperty('--radix-popper-anchor-width', `${anchorWidth}px`);
            contentStyle.setProperty('--radix-popper-anchor-height', `${anchorHeight}px`);
          },
        }),
        arrow && floatingUIarrow({ element: arrow, padding: arrowPadding }),
        transformOrigin({ arrowWidth, arrowHeight }),
        hideWhenDetached && hide({ strategy: 'referenceHidden', ...detectOverflowOptions }),
      ],
    });

    const [placedSide, placedAlign] = getSideAndAlignFromPlacement(placement);

    const handlePlaced = useCallbackRef(onPlaced);
    useLayoutEffect(() => {
      if (isPositioned) {
        handlePlaced?.();
      }
    }, [isPositioned, handlePlaced]);

    const arrowX = middlewareData.arrow?.x;
    const arrowY = middlewareData.arrow?.y;
    const cannotCenterArrow = middlewareData.arrow?.centerOffset !== 0;

    const [contentZIndex, setContentZIndex] = React.useState<string>();
    useLayoutEffect(() => {
      if (content) setContentZIndex(window.getComputedStyle(content).zIndex);
    }, [content]);

    return (
      <div ref="{refs.setFloating}" data-radix-popper-content-wrapper="" style="{{" ...floatingStyles,="" transform:="" isPositioned="" ?="" floatingStyles.transform="" :="" 'translate(0,="" -200%)',="" keep="" off="" the="" page="" when="" measuring="" minWidth:="" 'max-content',="" zIndex:="" contentZIndex,="" ['--radix-popper-transform-origin'="" as="" any]:="" [="" middlewareData.transformOrigin?.x,="" middlewareData.transformOrigin?.y,="" ].join('="" '),="" hide="" content="" if="" using="" middleware="" and="" should="" be="" hidden="" set="" visibility="" to="" disable="" pointer="" events="" so="" UI="" behaves="" PopperContent="" isn't="" there="" at="" all="" ...(middlewareData.hide?.referenceHidden="" &&="" {="" visibility:="" 'hidden',="" pointerEvents:="" 'none',="" }),="" }}="" Floating="" interally="" calculates="" logical="" alignment="" based="" `dir`="" attribute="" on="" reference="" floating="" node,="" we="" must="" add="" this="" here="" ensure="" is="" calculated="" portalled="" well="" inline.="" dir="{props.dir}">
        <poppercontentprovider scope="{__scopePopper}" placedSide="{placedSide}" onArrowChange="{setArrow}" arrowX="{arrowX}" arrowY="{arrowY}" shouldHideArrow="{cannotCenterArrow}">
          <primitive.div data-side="{placedSide}" data-align="{placedAlign}" {...contentProps}="" ref="{composedRefs}" style="{{" ...contentProps.style,="" if="" the="" PopperContent="" hasn't="" been="" placed="" yet="" (not="" all="" measurements="" done)="" we="" prevent="" animations="" so="" that="" users's="" animation="" don't="" kick="" in="" too="" early="" referring="" wrong="" sides="" animation:="" !isPositioned="" ?="" 'none'="" :="" undefined,="" }}=""></primitive.div>
        </poppercontentprovider>
      </div>
    );
  }
);

팝퍼콘텐츠.displayName = 콘텐츠 이름;

/* -------------------------------------------------------------------------------------------------
 * 팝퍼 화살표
 * -----------------------------------------------------------------------------------------------*/

const ARROW_NAME = '팝퍼애로우';

const OPPOSITE_SIDE: Record<side, Side=""> = {
  top: 'bottom',
  right: 'left',
  bottom: 'top',
  left: 'right',
};

type PopperArrowElement = React.ElementRef<typeof ArrowPrimitive.Root="">;
유형 ArrowProps = React.ComponentPropsWithoutRef<typeof ArrowPrimitive.Root="">;
interface PopperArrowProps extends ArrowProps {}

const PopperArrow = React.forwardRef<popperarrowelement, PopperArrowProps="">(함수 팝퍼 화살표(
  props: ScopedProps<popperarrowprops>,
  forwardedRef
) {
  const { __scopePopper, ...arrowProps } = props;
  const contentContext = useContentContext(ARROW_NAME, __scopePopper);
  const baseSide = OPPOSITE_SIDE[contentContext.placedSide];

  return (
    // we have to use an extra wrapper because `ResizeObserver` (used by `useSize`)
    // doesn't report size as we'd expect on SVG elements.
    // it reports their bounding box which is effectively the largest path inside the SVG.
    <span ref="{contentContext.onArrowChange}" style="{{" position:="" 'absolute',="" left:="" contentContext.arrowX,="" top:="" contentContext.arrowY,="" [baseSide]:="" 0,="" transformOrigin:="" {="" '',="" right:="" '0="" 0',="" bottom:="" 'center="" '100%="" }[contentContext.placedSide],="" transform:="" 'translateY(100%)',="" 'translateY(50%)="" rotate(90deg)="" translateX(-50%)',="" `rotate(180deg)`,="" rotate(-90deg)="" translateX(50%)',="" visibility:="" contentContext.shouldHideArrow="" ?="" 'hidden'="" :="" undefined,="" }}="">
      <arrowprimitive.root {...arrowProps}="" ref="{forwardedRef}" style="{{" ...arrowProps.style,="" ensures="" the="" element="" can="" be="" measured="" correctly="" (mostly="" for="" if="" SVG)="" display:="" 'block',="" }}=""></arrowprimitive.root>
    </span>
  );
});

PopperArrow.displayName = ARROW_NAME;

/* -----------------------------------------------------------------------------------------------*/

함수 isNotNull<t>(value: T | null): value is T {
  return value !== null;
}

const transformOrigin = (options: { arrowWidth: number; arrowHeight: number }): Middleware => ({
  name: 'transformOrigin',
  options,
  fn(data) {
    const { placement, rects, middlewareData } = data;

    const cannotCenterArrow = middlewareData.arrow?.centerOffset !== 0;
    const isArrowHidden = cannotCenterArrow;
    const arrowWidth = isArrowHidden ? 0 : options.arrowWidth;
    const arrowHeight = isArrowHidden ? 0 : options.arrowHeight;

    const [placedSide, placedAlign] = getSideAndAlignFromPlacement(placement);
    const noArrowAlign = { start: '0%', center: '50%', end: '100%' }[placedAlign];

    const arrowXCenter = (middlewareData.arrow?.x ?? 0) + arrowWidth / 2;
    const arrowYCenter = (middlewareData.arrow?.y ?? 0) + arrowHeight / 2;

    let x = '';
    let y = '';

    if (placedSide === 'bottom') {
      x = isArrowHidden ? noArrowAlign : `${arrowXCenter}px`;
      y = `${-arrowHeight}px`;
    } else if (placedSide === 'top') {
      x = isArrowHidden ? noArrowAlign : `${arrowXCenter}px`;
      y = `${rects.floating.height + arrowHeight}px`;
    } else if (placedSide === 'right') {
      x = `${-arrowHeight}px`;
      y = isArrowHidden ? noArrowAlign : `${arrowYCenter}px`;
    } else if (placedSide === 'left') {
      x = `${rects.floating.width + arrowHeight}px`;
      y = isArrowHidden ? noArrowAlign : `${arrowYCenter}px`;
    }
    return { data: { x, y } };
  },
});

function getSideAndAlignFromPlacement(placement: Placement) {
  const [side, align = 'center'] = placement.split('-');
  return [side as Side, align as Align] as const;
}

const Root = Popper;
const Anchor = PopperAnchor;
const Content = PopperContent;
const Arrow = PopperArrow;

export {
  createPopperScope,
  //
  Popper,
  PopperAnchor,
  PopperContent,
  PopperArrow,
  //
  Root,
  Anchor,
  Content,
  Arrow,
  //
  SIDE_OPTIONS,
  ALIGN_OPTIONS,
};
export type { PopperProps, PopperAnchorProps, PopperContentProps, PopperArrowProps };
</t></popperarrowprops></popperarrowelement,></typeof></typeof></side,></string></htmlspanelement></htmldivelement></poppercontentprops></poppercontentelement,></record<side,></typeof></poppercontentcontextvalue></popperanchorelement></popperanchorprops></popperanchorelement,></measurable></typeof></typeof></measurable></popperprops></popperprops></poppercontextvalue></p>